
.textContainer {

  p {
    line-height: 1.4;
  }

  .textAnimation {
  
    &:after {
      content: '|';
      animation: blink 0.75s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      font-family: auto;
    }
    
    @keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  
  
  .marginTop {
    margin-top: 1.4rem;
  }
  
  .marginBottom {
    margin-bottom: 1.4rem;
  }
  
}


