.container {
  max-width: 100%;
  position: relative;
  height: 100% !important;

  @media screen and (max-width: 600px) {
    padding-right: 2rem;
  }

  .swiper {
    max-width: 75rem;
    height: 100% !important;
  }

  .swiperSlide {
    background-color: var(--base-100);
    height: 100% !important;
  }

  div.quizTypesBtnsDark {
    background-color: #2d2e37;
  }

  .quizTypesBtns {
    background-color: var(--base-200);
    position: absolute;
    right: 0rem;
    top: -0.9rem;
    z-index: 50;
    padding: 0.22rem;
    display: flex;
    align-items: center;
    border-radius: 0.3em;

    .buttonDark {
      color: #b0b2be;
    }

    .selectedButton {
      background-color: var(--base-50);
      color: var(--base-content);
    }

    button {
      cursor: pointer;
      font-weight: 600;
      font-size: 1.6rem;
      background-color: transparent;
      padding: 0.4rem 2rem;
      color: #99bafd;
      border-radius: 0.3em;
      text-transform: capitalize;
      transition: all 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275);

      &:active {
        transform: scale(1.14);
      }
    }
  }
}
