.form {
  width: 44rem;
  position: relative;
  background-color: var(--base-50);

  @mixin heading() {
    font-size: 2.4rem;
    font-weight: 400;
  }

  @mixin mediaSize() {
    width: 100%;
    height: 100%;
  }

  .closeButton {
    position: absolute;
    z-index: 10;
    top: 1rem;
    left: 1rem;
    background-color: #f0f5fd !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);

    .closeIcon {
      position: absolute;
      font-weight: 900;
      color: #3e416d;
    }
  }

  .imgContainer {
    background-color: #d5cdfa;
    width: 100%;
    height: 17rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .heading {
      @include heading();
      padding: 3rem;
    }

    img {
      object-fit: cover;
      display: block;
      @include mediaSize();
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    padding: 4.4rem;

    @media screen and (max-width: 600px) {
      padding: 2.8rem 3rem;
    }

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.8rem;

      .heading {
        @include heading();
        margin-bottom: 1rem;
        text-align: center;
      }

      p {
        color: #9798a9;
      }
    }

    .textarea {
      background-color: var(--base-100);
      width: 100%;
      padding: 1.4rem;
      font-size: 1.6rem;
      border-radius: 0.7rem;

      &::placeholder {
        color: #9798a9;
      }
    }

    .footer {
      color: #9798a9;
      display: flex;
      flex-direction: column;
      font-size: 1.2rem;
      align-items: center;
      justify-content: center;

      a {
        color: #9798a9;
        text-decoration: underline;
        cursor: pointer;
      }

      p {
        text-align: center;
      }
    }
  }
}
