.stars {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;

  .star {
    cursor: pointer;
    width: 4rem;
    height: 4rem;
  }
}
