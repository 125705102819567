.pageWrapper {
  display: flex;
  flex-direction: column;
  gap: 8rem;
  margin: 2rem 0rem;
  margin-bottom: 6rem;

  @media screen and (max-width: 600px) {
    padding-right: 2rem;
  }
}

.skeletons {
  padding: 0;
  display: flex;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding-left: 2.55rem;

  @media screen and (max-width: 600px) {
    :last-child {
      display: none;
    }
  }
}

.heading {
  padding-top: 0.8rem;
  padding-left: 2.55rem;
}

.container {
  min-height: 44rem;
  padding: 2.44rem 0;
  border-radius: 1.6rem;
  background-color: var(--base-50);
  border: 1px solid #e5f3ff;
  box-shadow: 0px 1px 3px 0px #e5f3ff9c;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;

  h2 {
    font-weight: 400;
    font-size: 2.2rem;
  }
}

.card {
  width: 19rem !important;
  border: 1px solid #e5f3ff;
  box-shadow: 0px 1px 3px 0px #e5f3ff9c;
  border-radius: 1.2rem;
  margin-bottom: 3.5px;
  overflow: hidden;
  min-height: 240px;

  img {
    height: 11rem;
    width: 100%;
    object-fit: cover;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.4rem 1.6rem;
    font-size: 1.45rem;

    h4 {
      font-size: 1.5rem;
    }
  }
}

div.buttonSelected {
  color: #61b6dd;
  background-color: #ddf4ff;
  border-color: #84d8ff;
}

.button {
  cursor: grab;
  transition: all 0.2s;
  border-radius: 1rem;
  padding: 0.8rem 1.6rem;
  font-weight: 800;
  font-size: 1.6rem;
  border: 2px solid #f5fafe;
  background-color: #f5fafe;
  color: #8caecf;
  text-align: center;
}
