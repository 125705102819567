.resultWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  padding: 2rem;
}

.resultContainer {
  background-color: var(--base-50);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60rem;
  height: 46rem;
  gap: 2rem;
  margin-top: -1.6rem;
  border-radius: 0.8rem;
  padding: 3rem;
  overflow: hidden;

  @media screen and (max-width: 600px) {
    margin-top: 5rem;
  }
}

.progresBar {
  width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  .steps {
    margin-top: 0.6rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
  }
}

.figure {
  background-color: var(--base-50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  max-width: 40rem;
  height: 100%;
  margin: auto;

  @media screen and (max-width: 400px) {
    max-width: 30rem;
  }

  .lottie {
    height: 16rem;
    width: 100%;
  }

  article {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 100%;

    p {
      text-align: center;
      font-size: 1.5rem;
      width: 100%;
    }
  }
}
