.app {
  background-color: var(--base-100);
  font-weight: 500;
  height: 100%;
  display: flex;
  flex-direction: row;

  @mixin points() {
    width: 16rem;
    height: auto;
    position: absolute;

    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  .pointsRight {
    top: 18rem;
    right: 1.2rem;
    @include points();

  }

  .pointsBottom {
    bottom: 2.4rem;
    left: 24rem;
    @include points();
  }
}
