.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    align-items: flex-end;
  }

  .form {
    width: 44rem;
    // height: 95%;

    @mixin heading() {
      font-size: 2.4rem;
      font-weight: 400;
    }

    @mixin mediaSize() {
      min-height: 13rem;
      max-height: 25rem;
      width: 100%;
      height: auto;
    }

    .imgContainer {
      background-color: #d5cdfa;
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      .heading {
        @include heading();
        padding: 3rem;
      }

      img {
        object-fit: cover;
        display: block;
        @include mediaSize();
      }
    }

    div.fieldsListDark {
      background-color: #202123;
    }

    .fieldsList {
      display: flex;
      flex-direction: column;
      gap: 1.7rem;
      background-color: var(--base-100);
      padding: 2.8rem 3.2rem;
      height: 80%;

      @media screen and (max-width: 600px) {
        padding: 2.8rem 3rem;
      }

      .heading {
        @include heading();
        margin-bottom: 1rem;
      }

      p {
        color: #9798a9;
      }

      .fields {
        overflow-y: auto;
        height: 80%;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .fieldGroup {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .title {
            color: var(--base-content);
            font-weight: 700;
          }

          .field {
            display: flex;
            align-items: center;
            font-size: 1.53rem;
            gap: 0.2rem;

            .label {
              background-color: var(--base-50);
              padding: 0.7rem 1.1rem;
              border-radius: 0.3rem;
              width: 35%;
              display: flex;

              p {
                color: var(--base-content);
                font-weight: 600;
                font-size: 1.53rem;
              }

              img {
                width: 1.8rem;
                height: 1.8rem;
                margin-right: 1rem;
              }
            }

            .noImage {
              padding-left: 3.5rem;
            }

            select {
              color: var(--base-content);
              padding: 0.7rem 1.1rem;
              border-radius: 0.3rem;
              font-size: 1.53rem;
              font-weight: 500;
              width: 65%;
              background-color: var(--base-50);
              appearance: none;
              background-image: url("../../../assets/angle-down-solid.svg");
              background-repeat: no-repeat;
              background-position: right 1rem center;
              background-size: 1em;

              &::placeholder {
                color: var(--inputPlaceholder);
              }
            }
          }
        }
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1.5rem;
        margin-top: 2rem;
      }
    }
  }
}

// CSS TRANSITION
.modalEnter {
  opacity: 0;
  transform: scale(0.9);

  @media screen and (max-width: 600px) {
    transform: translateY(100%);
  }
}

.modalEnterActive {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: translateX(0) scale(1);

  @media screen and (max-width: 600px) {
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translateY(0%);
  }
}

.modalExit {
  opacity: 1;
  transform: scale(1);

  @media screen and (max-width: 600px) {
    transform: translateY(0%);
  }
}

.modalExitActive {
  opacity: 0;
  transform: scale(0.9);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  @media screen and (max-width: 600px) {
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translateY(100%);
  }
}

// CSS TRANSITION
