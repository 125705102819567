@mixin card() {
  overflow: hidden;
  background-color: var(--base-50);
  border-radius: 0.46rem;
  border-bottom: 4.2px solid var(--card-border);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.44rem;
  font-weight: 600;
  position: relative;
  width: 100%;
  padding: 1.8rem 2.4rem;
  gap: 1.8rem;

  img {
    height: 4.4rem;
    width: auto;
  }
}

.cardsList {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;

  h3 {
    font-weight: 400;
    font-size: 1.58rem;
  }

  .cardsContainer {
    width: 100%;

    .swiper {
      height: 100%;
      padding: 0.6rem 0;

      .swiperSlide {
        height: 100%;
        display: flex;

        @media screen and (max-width: 600px) {
          &:not(:last-child) {
            width: 16.4rem !important;
          }
        }

        &:last-child {
          width: 0rem !important;
        }

        .ghostSlide {
          width: 0rem;
        }
      }
    }

    --comingSoonBrandHeight: 2.1rem;

    div.cardDark {
      background-color: #3e3f4b;
      border-bottom: 4.2px solid #2d2e37;
    }

    .card {
      @include card();
      text-align: center;
      cursor: pointer;
      transition: all 0.105s ease-in-out;

      &:hover {
        transform: scale(1.027);
      }

      &:active {
        transform: scale(1) translateY(2px);
      }
    }

    .cardComingSoon {
      @include card();

      .cardHeading {
        background-color: var(--secondary-50);
        width: 100%;
        padding: 0.2rem;
        text-align: center;
        color: var(--base-50);
        font-weight: 900;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        height: var(--comingSoonBrandHeight);
      }

      .cardHeadingDark {
        background-color: var(--base-50);
        color: #ffff;
      }

      .cardBody {
        margin-top: -0.8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 1.2rem;
        padding: 1.1rem;
        height: 15.4rem;
      }
    }
  }
}

.littleBackDrop {
  position: absolute;
  width: 110%;
  height: 110%;
  background-color: rgba(0, 0, 0, 0.26);
  pointer-events: none;
}
