.container {
  background-color: #4d4e87;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0.94rem;

  gap: 1.4rem;

  h4 {
    font-weight: 500;
    strong {
      font-weight: 550;
      color: #00ceac;
    }
  }

  .btn {
    &:link,
    &:visited,
    &:hover,
    &:active {
      font-weight: 500;
      text-decoration: underline;
      color: #fdc042;
    }
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
}
