.buttonDark {
  border: none !important;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;

  .logo {
    height: 3.4rem;
    width: auto;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.page {
  background-color: var(--base-100);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  .card {
    background-color: var(--base-50);
    width: 68rem;
    border-radius: 3rem;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2.2rem;

    .einstein {
      height: 18rem;
      width: auto;
    }

    &Body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.2rem;

      h3 {
        font-weight: 400;
        font-size: 2.1rem;
      }

      div {
        line-height: 3rem;

        p {
          text-align: center;
        }
      }
    }
  }
}
