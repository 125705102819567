// <CSSTransition>
.enter {
  transform: translateX(-100%);
}
.enterActive {
  opacity: 1;
  transition: all 0.3s linear; 
  transform: translateX(0);
}

.exit {
  opacity: 1;
  transform: translateX(0);
}
.exitActive {
  transition: all 0.3s ease-in-out;
  transform: translateX(-100%);
}
// <CSSTransition />
