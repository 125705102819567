.backrDrop {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  background-color: rgba(0, 0, 0, 0.26);
}

.navbar {
  display: none;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  top: 0;
  right: 0;
  z-index: 15;
  padding: 1.8rem 2rem;
  transition: background-color 0.25s ease-in-out;
  background-color: var(--base-100);

  @media screen and (max-width: 900px) {
    display: flex;
    width: 100%;
  }

  .toggleButton {
    font-size: 2.8rem;
  }

  .menuContainer {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    position: fixed;
  }

  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.7rem;

    .logo {
      height: 3.4rem;
      width: auto;
    }
  }
}

nav.navbarBackground {
  background-color: var(--base-50);
  box-shadow: 1px 0px 6px 2px rgba(0, 0, 0, 0.1);
}
