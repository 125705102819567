.container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  .loadingContainer {
    width: 50%;
    border-radius: 1rem;
    height: 50%;
    background: var(--base-50);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 900px) {
      width: 80%;
    }
  }

  

  .cloud {
    width: 50%;
    border-radius: 1rem;
    background: var(--base-50);
    height: 50%;
    color: var(--base-content);
    position: relative;
    padding: 0rem 2rem 0 2rem;
    display: flex;
    flex-direction: column;

    .back {
      width: 50%;
      padding-top: 1rem;
      img {
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
      }
      @media screen and (max-width: 900px) {
        width: 90%;
      }
    }

    @media screen and (max-width: 900px) {
      width: 90%;
      margin-bottom: 10rem;
    }

    &:before {
      content: "";
      position: absolute;
      right: 0;
      bottom: -1rem;
      width: 0;
      height: 0;
      border: 5em solid transparent;
      border-radius: 0.5rem;
      border-left-color: var(--base-50);
      border-right: 0;
      border-bottom: 0;
      margin-right: -3.5rem;
      transform: rotate(15deg);

      @media screen and (max-width: 900px) {
        transform: rotate(35deg);
        bottom: -1.5rem;
        right: 12rem;
      }
    }

    .content {
      font-size: 1.8rem;
      font-weight: 500;
      z-index: 1;
      overflow-y: scroll;

      .title {
        font-size: 2rem;
        font-weight: bold;
        margin: 1rem 0rem 1rem 0rem;
      }
    }
  }

  .buttons {
    margin-top: 2rem;
    display: flex;
    gap: 2rem;

    @media screen and (max-width: 900px) {
      position: absolute;
      bottom: 0;
      left: 16%;
      bottom: 5%;
      flex-direction: column;
    }
  }

  .animation {
    position: absolute;
    height: 30rem;
    width: auto;
    bottom: 0;
    right: 3%;
    z-index: 1;

    @media screen and (max-width: 900px) {
      height: 20rem;
    }

    @media screen and (max-width: 600px) {
      height: 18rem;
      left: 60%;
      right: 0;
    }
  }
}
