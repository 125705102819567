.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 0rem;
  background-color: var(--base-100);

  &:has(textarea) {
    padding-bottom: 1rem;
  }

  // @media screen and (max-width: 600px) {
  //   padding-bottom: 2rem;
  // }

  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.2rem;

    li.inputGroup {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      position: relative;

      label {
        font-family: var(--font-title);
        font-weight: 300;
        font-size: 1.58rem;
      }

      .field {
        position: relative;
        border-radius: 0.3rem;
        overflow: hidden;
      }

      @mixin input() {
        display: block;
        color: var(--base-content);
        font-size: 1.6rem;
        font-weight: 500;
        width: 100%;
        height: 100%;
        background-color: var(--base-50);

        &::placeholder {
          color: var(--inputPlaceholder);
        }
      }

      input {
        @include input();
        padding: 1.4rem;
      }

      textarea {
        @include input();
        padding: 1.4rem;
        resize: none;
        overflow: auto;
        height: 25rem;
      }
    }

    .button {
      align-self: center;
      overflow: hidden;
      position: relative;
      width: 40%;
      border-radius: 0.4rem;

      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }
}

// CSS TRANSITION
.modalEnter {
  opacity: 0;
  transform: scale(0.9);
}

.modalEnterActive {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.2s;
}

.modalExit {
  opacity: 1;
}

.modalExitActive {
  opacity: 0;
  transform: scale(0.9);
  transition: all 0.2s;
}

// CSS TRANSITION
