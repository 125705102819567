.container {
  height: 100vh;
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  overflow-x: hidden;

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  .swiper {
    max-width: 75rem;
    align-self: stretch;

    @supports (min-height: 100dvh) {
      min-height: 100dvh !important;
    }
  }

  .swiperSliderMain {
    height: 100% !important;
  }

  .swiperSliderResult {
    height: 100% !important;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .jesus {
    position: absolute;
    height: 28rem;
    width: auto;
    bottom: 0;
    right: 0;
    z-index: 1;

    @media screen and (max-width: 800px) {
      display: none;
    }
  }
}
