.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    align-items: flex-end;
  }
}

// CSS TRANSITION
.modalEnter {
  opacity: 0;
  transform: scale(0.9);

  @media screen and (max-width: 600px) {
    transform: translateY(100%);
  }
}

.modalEnterActive {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: translateX(0) scale(1);

  @media screen and (max-width: 600px) {
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translateY(0%);
  }
}

.modalExit {
  opacity: 1;
  transform: scale(1);

  @media screen and (max-width: 600px) {
    transform: translateY(0%);
  }
}

.modalExitActive {
  opacity: 0;
  transform: scale(0.9);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  @media screen and (max-width: 600px) {
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translateY(100%);
  }
}

// CSS TRANSITION
