.history {
  overflow-y: scroll;
  margin-top: 1rem;

  .dayContainer {
    margin-top: 1rem;

    .emptyText {
      font-size: 1.5rem;
      padding-left: 0.5rem;
      color: #ECECF1;
      span {
        // underline span
        text-decoration: underline;
      }
      &:last-child {
        margin-top: 2rem;
      }
    }

    .day {
      font-size: 1.2rem;
      color: #8E8EA0;
      margin-bottom: 1rem;
      padding-left: 0.5rem;

      @media screen and (max-width: 900px) {
        font-size: 1.5rem;
      }
    }

    .historyObj {
      display: flex;
      flex-direction: row;
      gap: 0.8rem;
      border-radius: 5px;
      padding: 1rem;
      cursor: pointer;

      &:hover {
        background-color: #2a2b31;
      }

      p {
        font-size: 1.5rem;
        color: #ECECF1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      img {
        width: 1.8rem;
        height: 1.8rem;
      }

      .delete {
        display: none;
        margin-left: auto;

        img { 
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      &:hover .delete {
        display: block; 
      }
    }
  }
}
