.sidebar {
  background-color: #202123;
  height: 100vh;

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  max-width: 16.5vw;
  z-index: 10;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    display: none;
  }

  h1 {
    color: #ECECF1;
    font-family: var(--font-content);
    padding-left: 0.5rem;
  }

  hr {
    border: none;
    height: 1px;
    background-color: #3F4144;
    margin: 1.5rem 0;
  }

  .bottom {
    margin-top: auto;
  }
}

.navbar {
  background-color: #202123;
  height: 100vh;

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  max-width: 80%;
  z-index: 10;
  padding: 2rem;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    max-width: 30%;
  }

  @media screen and (max-width: 650px) {
    max-width: 50%;
  }

  @media screen and (max-width: 450px) {
    max-width: 80%;
  }

  h1 {
    color: #ECECF1;
    font-family: var(--font-content);
    padding-left: 0.5rem;
    font-size: 2.5rem;
  }

  hr {
    border: none;
    height: 1px;
    background-color: #3F4144;
    margin: 2rem 0;
  }

  .bottom {
    margin-top: auto;
  }
}
