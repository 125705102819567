.rrss {
  margin-top: 1rem;
  display: flex;
  width: 100%;
  gap: 1.5rem;

  div {
    width: 20%;
    img {
      width: 100%;
    }
  }
}