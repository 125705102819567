@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&family=Quicksand:wght@400;500;600;700&display=swap");

:root {
  font-size: 62.5%;
  touch-action: pan-x pan-y;
  box-sizing: border-box;

  --font-content: "Quicksand", sans-serif;
  --font-title: "Fredoka One", cursive;
  --primary-50: #4d4e87;
  --primary-75: #3e416e;
  --primary-100: rgb(52, 55, 87);
  --primary-200: #2e2f4d;
  --primary-300: rgb(41, 43, 68);
  --primary-content-gray: #a0adc5;
  --primary-content: #ffffff;

  --navLink: #008afc;
  --inputPlaceholder: #9798a9;

  --secondary-50: #3686ff;
  --secondary-100: #2f7df1;
  --secondary-200: #2c6ed1;
  --secondary-300: #2867c5;

  --transparent: transparent;

  --success-50: #00ceac;
  --success-100: rgb(0, 187, 156);
  --success-200: #0d9680;
  --success-300: rgb(2, 140, 117);
  --success-content-50: #d8fbe5;
  --success-content-100: #bfedd0;

  --error-50: rgb(255, 86, 128);
  --error-100: #f24f78;
  --error-200: #b23654;
  --error-300: rgb(178, 47, 79);
  --error-content-50: rgb(251, 216, 215);
  --error-content-100: rgb(235, 196, 194);

  --gray-250: #e5eeff;
}

[data-theme="light"] {
  --base-50: rgb(255, 255, 255);
  --base-100: #f0f5fd;
  --base-200: #e5eeff;

  --browser: #3e416e;
  --base-content: #3e416d;
  --base-shadow: rgba(62, 65, 109, 0.1);

  --card-border: #d0daeb;

  --bg-skeleton-50: #d9e8fa;
  --bg-skeleton-100: #c9daef;

  --selectedCard: #d6ecff;
  --selectedCardBorder: #99cefd;
}

[data-theme="dark"] {
  --base-25: #40414f;
  --base-50: #494a5d; //#343541
  --base-100: #343541;
  --base-200: #343541;

  --browser: #000;
  --base-content: rgb(218, 218, 226);
  --base-shadow: rgba(62, 65, 109, 0.1);

  --bg-skeleton-50: #40414f;
  --bg-skeleton-100: #292929;

  --selectedCard: #494a5d;
  --selectedCardBorder: #3e3f4b;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  font-family: var(--font-content);
  margin: 0;
  padding: 0;
  border: none;
  scroll-behavior: smooth;
  user-select: none;
}

h1,
h2,
h3 {
  font-family: var(--font-title);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

*:focus {
  outline: none;
}

body {
  font-family: var(--font-content);
  color: var(--base-content);
  background-color: var(--base-100);
  font-size: 1.6rem;
  min-height: 100vh;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  overflow-y: hidden;
}

::-webkit-scrollbar {
  width: 0px;
}

@media screen and (max-width: 900px) {
  :root {
    font-size: 59%;
  }
}

@media screen and (max-width: 600px) {
  :root {
    font-size: 56%;
  }
}
