.options {
  display: flex;
  flex-direction: column;

  .option {
    display: flex;
    flex-direction: row;
    gap: 0.9rem;
    border-radius: 5px;
    padding: 1rem;
    cursor: pointer;

    &:hover {
      background-color: #2a2b31;
    }

    p {
      font-size: 1.5rem;
      color: #ECECF1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}

.supportModal {
  width: 500px;
  height: 500px;
  background-color: #ECECF1;
}
