// <CSSTransition>
.enter {
  opacity: 0.2;
  // transform: translateY(-20%);
  // z-index: -10;
}
.enterActive {
  opacity: 1;
  transition: all 0.24s ease;
  // transform: translateY(0);
  // z-index: -10;
}

.exit {
  opacity: 1;
  // transform: translateY(0);
  // z-index: -10;
}
.exitActive {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  // transform: translateY(-20%);
  // z-index: -10;
}
// <CSSTransition />
