.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  height: 100%;
}

.main {
  height: 100%;
  width: 60rem;
  display: flex;
  flex-direction: column;
  gap: 3.6rem;
  overflow-y: scroll;
  overflow-x: hidden;

  @media screen and (max-width: 600px) {
    min-height: 100vh;
    gap: 2rem;

    @supports (min-height: 100dvh) {
      min-height: 100dvh;
    }

    width: 100%;
    padding: 0 2rem;
    padding-right: 0;
    padding-top: 4rem;
  }

  .quizGPTOptions {
    margin-top: 7rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    gap: 2.6rem;

    @media screen and (max-width: 600px) {
      padding-right: 2rem;
    }

    .logoContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.7rem;

      .logo {
        height: 3.4rem;
        width: auto;
      }

      @media screen and (max-width: 900px) {
        display: none;
      }
    }

    .textContent {
      text-align: center;
      margin-bottom: 1rem;

      p {
        @media screen and (max-width: 600px) {
          padding: 0 2rem;
        }
      }

      .heading {
        margin-bottom: 2.6rem;

        @media screen and (max-width: 600px) {
          padding: 0 3.8rem;
          font-size: 3rem;
        }
      }
    }
  }
}
