.container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  @supports (height: 100dvh) {
    height: 100dvh;
  }
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.38);
}
