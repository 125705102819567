.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    align-items: flex-end;
  }
}

.form {
  width: 44rem;
  position: relative;

  @mixin heading() {
    font-size: 2.4rem;
    font-weight: 400;
  }

  @mixin mediaSize() {
    width: 100%;
    height: 100%;
  }

  .closeButton {
    position: absolute;
    z-index: 10;
    top: 1rem;
    left: 1rem;
    background-color: #f0f5fd !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);

    .closeIcon {
      position: absolute;
      font-weight: 900;
      color: #3e416d;
    }
  }

  .imgContainer {
    background-color: #d5cdfa;
    width: 100%;
    height: 17rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .heading {
      @include heading();
      padding: 3rem;
    }

    img {
      object-fit: cover;
      display: block;
      @include mediaSize();
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.7rem;
    background-color: var(--base-100);
    padding: 2.8rem 3.2rem;

    @media screen and (max-width: 600px) {
      padding: 2.8rem 3rem;
    }

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;

      .heading {
        @include heading();
        margin-bottom: 1rem;
      }

      p {
        color: #9798a9;
      }
    }

    .fieldGroup {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      button {
        display: flex;
        align-items: center;
        font-size: 1.53rem;
        width: 45%;
        gap: 0.2rem;
        border-radius: 0.3rem;
        padding: 0.4rem;
        cursor: pointer;

        p {
          color: white;
          font-weight: 500;
          font-size: 1.53rem;
        }

        img {
          width: 3rem;
          height: 3rem;
          margin-right: 1rem;
        }
      }

      .google {
        background-color: #4284f3;
      }

      .facebook {
        background-color: #39579d;
      }
    }

    .footer {
      color: #9798a9;
      display: flex;
      flex-direction: column;
      font-size: 1.2rem;
      align-items: center;
      justify-content: center;

      a {
        color: #9798a9;
        text-decoration: underline;
        cursor: pointer;
      }

      p {
        text-align: center;
      }
    }
  }
}

// CSS TRANSITION
.modalEnter {
  opacity: 0;
  transform: scale(0.9);

  @media screen and (max-width: 600px) {
    transform: translateY(100%);
  }
}

.modalEnterActive {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: translateX(0) scale(1);

  @media screen and (max-width: 600px) {
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translateY(0%);
  }
}

.modalExit {
  opacity: 1;
  transform: scale(1);

  @media screen and (max-width: 600px) {
    transform: translateY(0%);
  }
}

.modalExitActive {
  opacity: 0;
  transform: scale(0.9);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  @media screen and (max-width: 600px) {
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translateY(100%);
  }
}

// CSS TRANSITION
