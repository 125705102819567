.button {
  padding: 0.88rem 1rem;
  display: block;
  max-width: 100%;
  min-width: 13.4rem;
  border-bottom: 4px solid;
  border-radius: 0.4rem;
  font-weight: 400;
  font-size: 1.68rem;
  font-family: var(--font-title);
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 600px) {
    padding: 1.3rem 1rem;
    font-size: 1.9rem;
  }
}

.success {
  background-color: var(--success-50);
  border-color: var(--success-200);

  &:hover {
    background-color: var(--success-100);
    border-color: var(--success-300);
  }
}

.error {
  background-color: var(--error-50);
  border-color: var(--error-200);

  &:hover {
    background-color: var(--error-100);
    border-color: var(--error-300);
  }
}

.secondary {
  background-color: var(--secondary-50);
  border-color: var(--secondary-200);

  &:hover {
    background-color: var(--secondary-100);
    border-color: var(--secondary-300);
  }
}

.primary {
  background-color: var(--primary-50);
  border-color: var(--primary-200);

  &:hover {
    background-color: var(--primary-75);
    border-color: var(--primary-300);
  }
}

.purple {
  background-color: #3e416d;
  border-color: var(--primary-200);

  &:hover {
    background-color: var(--primary-75);
    border-color: var(--primary-300);
  }
}

.defaultDark {
  background-color: var(--base-50) !important;
  border: none;
  border-bottom: 4px solid #3e3f4b !important;
  box-shadow: none !important;

  &:hover {
    background-color: var(--secondary-25);
    border-color: var(--secondary-35);
    // color: #fff !important;
  }
}

.default {
  background-color: var(--base-50);
  color: var(--base-content) !important;
  border-bottom: 4px solid #d0def8;

  &:hover {
    background-color: var(--secondary-content-100);
    border-color: #d0def8;
  }
}

.defaultDisable:hover {
  pointer-events: none !important;
}
